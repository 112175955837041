export enum SmartDeviceStates {
  PARKED = 'parked',
  PARKED_AND_CHARGING = 'parkedAndCharging',
  PARKED_AND_CHARGE_COMPLETE = 'parkedAndChargeComplete',
  DRIVING = 'driving',
  IDLE = 'idle',
  MAINTENANCE_MODE = 'maintenanceMode',
}

export enum SmartDeviceType {
  ANADOLU = 'anadolu',
  GEMLIK = 'gemlik',
  KAPADOKYA = 'kapadokya',
  KULA = 'kula',
  OLTU = 'oltu',
  PAMUKKALE = 'pamukkale',
}

export interface SmartDevice {
  tokenId?: string | null
  uniqueAssetId: string
  name: string
  state: SmartDeviceStates
  type: SmartDeviceType
  engineOn: boolean
  batteryPercentage: number
  ownerWalletAddress: string
  isMasterKey?: boolean | null
  isProcessing: boolean
  isAvaxRefundRequired: boolean
}

export interface MockSmartDevice {
  uniqueAssetId: string
  name: string
  state: SmartDeviceStates
  type: SmartDeviceType
  engineOn: boolean
  batteryPercentage: number
}
