import React, { FC } from 'react'
import { SmartDevice } from '../../declerations/smart-device.types'
import { useTranslation } from 'react-i18next'
import { Text, VStack } from '@chakra-ui/react'

const SmartDeviceInfo: FC<{ smartDevice: SmartDevice }> = ({ smartDevice }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'controlCard' })

  return (
    <VStack spacing={2} align="stretch">
      <Text>
        <b>{t('tokenId')}:</b> {smartDevice.tokenId}
      </Text>
      <Text>
        <b>{t('name')}:</b> {smartDevice.name}
      </Text>
      <Text>
        <b>{t('batteryPercentage')}:</b> {smartDevice.batteryPercentage}%
      </Text>
      <Text>
        <b>{t('vin')}:</b> {smartDevice.uniqueAssetId}
      </Text>
      <Text>
        <b>{t('state')}:</b> {t(smartDevice.state)}
      </Text>
      <Text>
        <b>{t('owner')}</b>
        {smartDevice.ownerWalletAddress}
      </Text>
    </VStack>
  )
}

export default SmartDeviceInfo
