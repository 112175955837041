import { EventDataMap } from './events.manager.types'

type SubscriberCallback<Event extends keyof EventDataMap> = (data: EventDataMap[Event]) => void

class EventsManager {
  private subscribers: { [Event in keyof EventDataMap]?: SubscriberCallback<Event>[] } = {}

  subscribe<Event extends keyof EventDataMap>(eventType: Event, callback: SubscriberCallback<Event>): void {
    if (!this.subscribers[eventType]) {
      this.subscribers[eventType] = []
    }
    this.subscribers[eventType]?.push(callback)
  }

  unsubscribe<Event extends keyof EventDataMap>(eventType: Event, callback: SubscriberCallback<Event>): void {
    const callbacks = this.subscribers[eventType]
    if (!callbacks) {
      return
    }

    const index = callbacks.indexOf(callback)
    if (index !== -1) {
      callbacks.splice(index, 1)
    }
  }

  publish<Event extends keyof EventDataMap>(eventType: Event, data: EventDataMap[Event]): void {
    this.subscribers[eventType]?.forEach(callback => {
      ;(callback as SubscriberCallback<Event>)(data)
    })
  }
}

export const eventsManager = new EventsManager()
