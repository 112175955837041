import React from 'react'
import { useAuth } from 'react-oidc-context'
import { useTranslation } from 'react-i18next'
import { Box, Button, Center, Text } from '@chakra-ui/react'
import Layout from '../../components/layout/layout.component'

const LoginPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'login' })
  const auth = useAuth()

  const handleLogout = async () => {
    await auth.removeUser()
    await auth.signoutRedirect()
  }

  const Content = () => {
    switch (auth.activeNavigator) {
      case 'signinSilent':
        return <Text>{t('signinSilent')}</Text>
      case 'signoutRedirect':
        return <Text>{t('signoutRedirect')}</Text>
    }

    if (auth.isLoading) {
      return <Text>{t('loading')}</Text>
    }

    if (auth.error) {
      return (
        <>
          <Text mb={4}>{t('error', { message: auth.error.message })}</Text>
          <Button onClick={handleLogout}>{t('logout')}</Button>
        </>
      )
    }

    return (
      <>
        <Text mb={4}>{t('welcome')}</Text>
        <Button onClick={() => void auth.signinRedirect()}>{t('login')}</Button>
      </>
    )
  }

  return (
    <Layout>
      <Center height="calc(100dvh - 12rem)">
        <Box borderWidth={1} borderRadius="md" p={8} width="50rem" textAlign="center" boxShadow="md">
          <Content />
        </Box>
      </Center>
    </Layout>
  )
}

export default LoginPage
