import React, { FC, useMemo } from 'react'
import RegisterAssetModalProps from './register-asset-modal.component.types'
import { Box, Button, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Stack, Text } from '@chakra-ui/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ApiService } from '../../utils/api-service.util'
import AvaxDisplayer from '../avax-displayer/avax-displayer.component'
import { useTranslation } from 'react-i18next'
import { TfiReload } from 'react-icons/tfi'
import { useBalance } from '../../contexes/balance.context'
import BigNumber from 'bignumber.js'
import serverExceptionToast from '../../utils/server-exception-toast.util'
import getContractExceptionTranslation from '../../utils/contract-exception-translation.util'
import { AxiosError, AxiosResponse } from 'axios'
import { DryRunRegisterAssetCommandResponse } from '@vennyx-org/togg-smart-device-tokenization-api-client'
import ServerException from '../../declerations/server-exception.types'

const RegisterAssetModal: FC<RegisterAssetModalProps> = ({ onClose, uniqueAssetId, isOpen, onRegister }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'registerAssetModal' })
  const { balance } = useBalance()

  const dryRunRegisterAssetQuery = useQuery<AxiosResponse<DryRunRegisterAssetCommandResponse>, AxiosError<ServerException>>({
    queryKey: ['dryRunRegisterAsset', uniqueAssetId],
    queryFn: () => ApiService.commandsDryRunRegisterAssetPost({ uniqueAssetId: uniqueAssetId! }),
    enabled: !!uniqueAssetId && isOpen,
    retry: 1,
    retryDelay: 1000,
    gcTime: 10000,
  })

  const registerAssetQuery = useMutation({
    mutationKey: ['registerAsset', uniqueAssetId],
    mutationFn: () => ApiService.commandsRegisterAssetPost({ uniqueAssetId: uniqueAssetId! }),
    onSuccess: async ({ data }) => {
      if (data.isSucceeded) {
        onRegister()
      }
    },
    onError: serverExceptionToast,
  })

  const isBalanceEnough = useMemo(() => {
    if (!dryRunRegisterAssetQuery.data?.data?.totalGasFee) {
      return false
    }

    return new BigNumber(balance).isGreaterThan(new BigNumber(dryRunRegisterAssetQuery.data.data.totalGasFee))
  }, [balance, dryRunRegisterAssetQuery.data?.data?.totalGasFee])

  const handleRegisterAsset = async () => {
    if (!uniqueAssetId || !isBalanceEnough) {
      return
    }

    try {
      await registerAssetQuery.mutateAsync()
    } catch {
      // Ignored
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{t('title')}</ModalHeader>
        <ModalBody pb={6}>
          <Stack spacing={4} direction={{ base: 'column', md: 'row' }} alignItems={{ base: 'center', md: 'flex-start' }} h="100%">
            <Box minWidth={{ base: '100%', md: '48%' }} minHeight="9rem" borderRadius="xl" overflow="hidden" boxShadow="md" bg="white">
              <Text textAlign="center" fontWeight="500">
                {uniqueAssetId}
              </Text>
              <Image opacity={0.5} src="/images/smart_device_black_white.webp" alt="Smart Device" transform="scale(1.2)" userSelect="none" />
            </Box>

            <Box minWidth={{ base: '100%', md: '48%' }}>
              <Text>{t('estimatedGasPrice')}</Text>
              <Text color="gray.500" fontSize="xs">
                {t('estimatedGasPriceDesc1')}
              </Text>
              <Text color="gray.500" fontSize="xs" mb={2}>
                {t('estimatedGasPriceDesc2')}
              </Text>

              {dryRunRegisterAssetQuery.isPending && <Spinner mt={2} ml={2} />}
              {!dryRunRegisterAssetQuery.isError && dryRunRegisterAssetQuery.data?.data?.totalGasFee && (
                <>
                  <AvaxDisplayer value={dryRunRegisterAssetQuery.data.data.totalGasFee} />
                  <Text color="gray.500" fontSize="xs" mt={2}>
                    {t('estimatedGasPriceDesc3')}
                  </Text>
                </>
              )}
              {!dryRunRegisterAssetQuery.isPending && dryRunRegisterAssetQuery.isError && (
                <>
                  <Text color="red.500" fontSize="sm">
                    {getContractExceptionTranslation(dryRunRegisterAssetQuery.error.response?.data?.detail, 'registerAssetModal.errorEstimate')}
                  </Text>
                  <Button colorScheme="red" variant="ghost" w="100%" leftIcon={<TfiReload />} onClick={() => dryRunRegisterAssetQuery.refetch()}>
                    {t('tryAgain')}
                  </Button>
                </>
              )}
            </Box>
          </Stack>

          <Button
            mt={4}
            w="100%"
            onClick={handleRegisterAsset}
            isLoading={registerAssetQuery.isPending}
            isDisabled={dryRunRegisterAssetQuery.isPending || dryRunRegisterAssetQuery.isError || !isBalanceEnough}
          >
            {dryRunRegisterAssetQuery.isFetched && dryRunRegisterAssetQuery.isSuccess && !isBalanceEnough ? t('notEnoughBalance') : t('approve')}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default RegisterAssetModal
