import React, { createContext, ReactNode, useEffect } from 'react'
import { useAuth } from 'react-oidc-context'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import appConfig from '../configs/app.config'
import { eventsManager } from '../managers/events.manager'
import { EventDataMap } from '../managers/events.manager.types'

export const EventsContext = createContext<undefined>(undefined)
const EventsContextProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuth()
  useEffect(() => {
    const abortController = new AbortController()

    if (auth.user?.access_token) {
      ;(async () => {
        await fetchEventSource(appConfig.eventsApiUrl, {
          headers: {
            Authorization: `Bearer ${auth.user?.access_token}`,
          },
          signal: abortController.signal,
          onmessage: message => {
            console.log('Handling EventSource message', message)
            let data = message.data
            if (data.length > 0) {
              try {
                data = JSON.parse(data)
              } catch (error) {
                console.error('Failed to parse EventSource message data', error)
              }
            }
            eventsManager.publish(message.event as keyof EventDataMap, data)
          },
        })
      })()
    }

    return () => {
      abortController.abort()
    }
  }, [auth.user?.access_token])

  return <EventsContext.Provider value={undefined}>{children}</EventsContext.Provider>
}

export default EventsContextProvider
