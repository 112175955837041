import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { TfiReload } from 'react-icons/tfi'
import { MdErrorOutline, MdOutlineInfo } from 'react-icons/md'
import { Button, Container, Heading, HStack, Skeleton, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { SmartDevice } from '../../declerations/smart-device.types'
import { ApiService } from '../../utils/api-service.util'
import { getSmartDevice } from '../../utils/smart-device.util'
import Layout from '../../components/layout/layout.component'
import ControlCard from '../../components/control-card/control-card.component'
import RegisterAssetCard from '../../components/register-asset-card/register-asset-card.component'
import RegisterAssetModal from '../../components/register-asset-modal/register-asset-modal.component'
import SmartDeviceInfoModal from '../../components/smart-device-info-modal/smart-device-info-modal.component'
import FractionalizedHolderAddModal from '../../components/fractionalized-holder-add-modal/fractionalized-holder-add-modal.component'
import { eventsManager } from '../../managers/events.manager'
import ServerEventsTypes, {
  AssetRegisteredEventData,
  AssetRegisterProcessingEventData,
  AssetRegistrationFailedEventData,
  AvaxRefundRequiredEventData,
} from '../../declerations/server-events.types'
import serverExceptionToast from '../../utils/server-exception-toast.util'

const HomePage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'home' })
  const auth = useAuth()
  const toast = useToast()
  const infoModal = useDisclosure()
  const holdersModal = useDisclosure()
  const registerAssetModal = useDisclosure()

  const [smartDevices, setSmartDevices] = useState<SmartDevice[]>([])
  const [selectedSmartDevice, setSelectedSmartDevice] = useState<SmartDevice | undefined>(undefined)
  const [selectedUniqueAssetId, setSelectedUniqueAssetId] = useState<string | undefined>(undefined)
  const [failedRegisteringUniqueAssetIds, setFailedRegisteringUniqueAssetIds] = useState<string[]>([])

  const smartDevicesQuery = useQuery({
    queryKey: ['smartDevices', auth.user?.profile.sub],
    queryFn: ApiService.queriesMyAssetsGet,
    enabled: !!auth.user,
    staleTime: 1000 * 30,
    refetchOnWindowFocus: false,
  })

  const retryRefundToUserQuery = useMutation({
    mutationKey: ['retryRefundToUser'],
    mutationFn: (uniqueAssetId: string) => ApiService.commandsRetryRefundToUserPost({ uniqueAssetId: uniqueAssetId }),
    onSuccess: (data, variables) => {
      if (data.data.isSucceeded) {
        toast({
          title: t('refundCalled'),
          status: 'success',
        })

        setSmartDevices(prevState => {
          const computedSmartDevices = [...prevState]
          const foundSmartDevice = prevState.find(smartDevice => smartDevice.uniqueAssetId === variables)
          if (foundSmartDevice) {
            foundSmartDevice.isAvaxRefundRequired = false
            computedSmartDevices.splice(prevState.indexOf(foundSmartDevice), 1, foundSmartDevice)
          }

          return computedSmartDevices
        })
      }
    },
    onError: serverExceptionToast,
  })

  useEffect(() => {
    if (failedRegisteringUniqueAssetIds.length !== 0) {
      failedRegisteringUniqueAssetIds.forEach(uniqueAssetId => {
        toast({
          title: t('failedToRegisterAsset', { uniqueAssetId: uniqueAssetId }),
          status: 'error',
        })
      })

      setFailedRegisteringUniqueAssetIds([])
    }
  }, [failedRegisteringUniqueAssetIds, t, toast])

  useEffect(() => {
    const handleAssetRegisterProcessing = (data: AssetRegisterProcessingEventData) => {
      setSmartDevices(prevState => {
        const computedSmartDevices = [...prevState]
        const foundSmartDevice = prevState.find(smartDevice => smartDevice.uniqueAssetId === data.uniqueAssetId)
        if (foundSmartDevice) {
          foundSmartDevice.isProcessing = true
          computedSmartDevices.splice(prevState.indexOf(foundSmartDevice), 1, foundSmartDevice)
        }

        return computedSmartDevices
      })
    }
    const handleAssetRegistered = (data: AssetRegisteredEventData) => {
      setSmartDevices(prevState => {
        const computedSmartDevices = [...prevState]
        const foundSmartDevice = prevState.find(smartDevice => smartDevice.uniqueAssetId === data.uniqueAssetId)
        if (foundSmartDevice) {
          foundSmartDevice.isProcessing = false
          foundSmartDevice.tokenId = data.masterAssetKeyTokenId
          foundSmartDevice.isMasterKey = true
          foundSmartDevice.ownerWalletAddress = data.userAccountId
          computedSmartDevices.splice(prevState.indexOf(foundSmartDevice), 1, foundSmartDevice)
        }

        return computedSmartDevices
      })
    }
    const handleAssetRegistrationFailed = (data: AssetRegistrationFailedEventData) => {
      setFailedRegisteringUniqueAssetIds(prevState => [...prevState, data.uniqueAssetId].filter((value, index, self) => self.indexOf(value) === index))
      setSmartDevices(prevState => {
        const computedSmartDevices = [...prevState]
        const foundSmartDevice = prevState.find(smartDevice => smartDevice.uniqueAssetId === data.uniqueAssetId)
        if (foundSmartDevice) {
          foundSmartDevice.isProcessing = false
          computedSmartDevices.splice(prevState.indexOf(foundSmartDevice), 1, foundSmartDevice)
        }

        return computedSmartDevices
      })
    }
    const handleAvaxRefundRequired = (data: AvaxRefundRequiredEventData) => {
      setSmartDevices(prevState => {
        const computedSmartDevices = [...prevState]
        const foundSmartDevice = prevState.find(smartDevice => smartDevice.uniqueAssetId === data.uniqueAssetId)
        if (foundSmartDevice) {
          foundSmartDevice.isAvaxRefundRequired = true
          computedSmartDevices.splice(prevState.indexOf(foundSmartDevice), 1, foundSmartDevice)
        }

        return computedSmartDevices
      })
    }

    eventsManager.subscribe(ServerEventsTypes.ASSET_REGISTER_PROCESSING, handleAssetRegisterProcessing)
    eventsManager.subscribe(ServerEventsTypes.ASSET_REGISTERED, handleAssetRegistered)
    eventsManager.subscribe(ServerEventsTypes.ASSET_REGISTRATION_FAILED, handleAssetRegistrationFailed)
    eventsManager.subscribe(ServerEventsTypes.AVAX_REFUND_REQUIRED, handleAvaxRefundRequired)

    return () => {
      eventsManager.unsubscribe(ServerEventsTypes.ASSET_REGISTER_PROCESSING, handleAssetRegisterProcessing)
      eventsManager.unsubscribe(ServerEventsTypes.ASSET_REGISTERED, handleAssetRegistered)
      eventsManager.unsubscribe(ServerEventsTypes.ASSET_REGISTRATION_FAILED, handleAssetRegistrationFailed)
      eventsManager.unsubscribe(ServerEventsTypes.AVAX_REFUND_REQUIRED, handleAvaxRefundRequired)
    }
  }, [])

  useEffect(() => {
    if (smartDevicesQuery.data) {
      const mySmartDevices = smartDevicesQuery.data.data.myAssets.map(asset => {
        const smartDevice = getSmartDevice(asset.uniqueAssetId)
        return {
          tokenId: asset.tokenId,
          isMasterKey: asset.isMasterKey,
          ownerWalletAddress: asset.ownerWalletAddress,
          isProcessing: asset.isProcessing,
          isAvaxRefundRequired: asset.isAvaxRefundRequired,
          ...smartDevice,
        }
      })
      setSmartDevices(mySmartDevices)
    } else {
      setSmartDevices([])
    }
  }, [smartDevicesQuery.data])

  const handleHoldersClick = (smartDevice: SmartDevice) => {
    setSelectedSmartDevice(smartDevice)
    holdersModal.onOpen()
  }

  const handleInfoClick = (smartDevice: SmartDevice) => {
    setSelectedSmartDevice(smartDevice)
    infoModal.onOpen()
  }

  const handleRegisterAsset = (uniqueAssetId: string) => {
    setSelectedUniqueAssetId(uniqueAssetId)
    registerAssetModal.onOpen()
  }

  const handleRegisterComplete = () => {
    registerAssetModal.onClose()
  }

  const handleAvaxRefund = async (uniqueAssetId: string) => {
    try {
      await retryRefundToUserQuery.mutateAsync(uniqueAssetId)
    } catch {
      // Ignored
    }
  }

  return (
    <>
      <Layout>
        <Container maxW="container.xl" paddingX={0}>
          <HStack justifyContent="space-between" mb={4} alignItems="center">
            <Heading as="h1" size="lg">
              {t('title')}
            </Heading>
            <Button variant="ghost" size="sm" leftIcon={<TfiReload />} isLoading={smartDevicesQuery.isFetching} onClick={() => smartDevicesQuery.refetch()}>
              {t('refresh')}
            </Button>
          </HStack>
          <Stack direction="row" spacing={4} wrap="wrap" justifyContent={{ base: 'center', md: 'flex-start' }} alignItems="center">
            {smartDevicesQuery.isFetching && Array.from(Array(4).keys()).map(index => <Skeleton key={index} width="19rem" height="28.75rem" borderRadius="xl" />)}
            {!smartDevicesQuery.isFetching &&
              smartDevicesQuery.isSuccess &&
              smartDevices.map((smartDevice, index) => {
                if (!smartDevice.tokenId) {
                  return (
                    <RegisterAssetCard
                      key={index}
                      uniqueAssetId={smartDevice.uniqueAssetId}
                      isWaitingForRegistering={smartDevice.isProcessing}
                      isAvaxRefundWaiting={false}
                      isAvaxRefundRequired={smartDevice.isAvaxRefundRequired}
                      onRegister={() => handleRegisterAsset(smartDevice.uniqueAssetId)}
                      onAvaxRefund={() => handleAvaxRefund(smartDevice.uniqueAssetId)}
                    />
                  )
                }
                return (
                  <ControlCard
                    key={index}
                    uniqueAssetId={smartDevice.uniqueAssetId}
                    state={smartDevice.state}
                    engineOn={smartDevice.engineOn}
                    batteryPercentage={smartDevice.batteryPercentage}
                    isOwner={smartDevice.isMasterKey ?? false}
                    imageUrl={`/images/smart_device_${smartDevice.type}.webp`}
                    ownerWalletAddress={smartDevice.ownerWalletAddress}
                    onHoldersClick={() => handleHoldersClick(smartDevice)}
                    onInfoClick={() => handleInfoClick(smartDevice)}
                  />
                )
              })}
            {!smartDevicesQuery.isFetching && smartDevicesQuery.isSuccess && smartDevices.length === 0 && (
              <HStack>
                <MdOutlineInfo />
                <Text>{t('noSmartDevices')}</Text>
              </HStack>
            )}
            {!smartDevicesQuery.isFetching && smartDevicesQuery.isError && (
              <HStack>
                <MdErrorOutline color="#E53E3E" />
                <Text color="red.500">{t('serverError')}</Text>
              </HStack>
            )}
          </Stack>
        </Container>
      </Layout>

      <FractionalizedHolderAddModal isOpen={holdersModal.isOpen} onClose={holdersModal.onClose} uniqueAssetId={selectedSmartDevice?.uniqueAssetId ?? ''} />
      <SmartDeviceInfoModal isOpen={infoModal.isOpen} onClose={infoModal.onClose} smartDevice={selectedSmartDevice} />
      <RegisterAssetModal uniqueAssetId={selectedUniqueAssetId} isOpen={registerAssetModal.isOpen} onClose={registerAssetModal.onClose} onRegister={handleRegisterComplete} />
    </>
  )
}

export default HomePage
