import React, { FC } from 'react'
import RegisterAssetCardProps from './register-asset-card.component.types'
import { Box, Button, Divider, HStack, Image, Skeleton, Text, VStack } from '@chakra-ui/react'
import { BiChevronRight } from 'react-icons/bi'
import { useTranslation } from 'react-i18next'
import { IoDuplicateOutline } from 'react-icons/io5'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { HiOutlineReceiptRefund } from 'react-icons/hi'

const RegisterAssetCard: FC<RegisterAssetCardProps> = ({ onRegister, uniqueAssetId, isWaitingForRegistering, isAvaxRefundRequired, isAvaxRefundWaiting, onAvaxRefund }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'registerAssetCard' })

  if (isWaitingForRegistering) {
    return (
      <Skeleton width="19rem" height="28.75rem" borderRadius="xl" position="relative">
        <VStack
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          p={4}
          width="100%"
          height="100%"
          alignItems="stretch"
          justifyContent="space-between"
          visibility="visible"
        >
          <Text fontSize="lg" color="black" fontWeight={500} textAlign="left" visibility="visible">
            {uniqueAssetId}
          </Text>
          <Text fontSize="md" color="black" fontWeight={500} textAlign="center" visibility="visible">
            {t('registering')}
          </Text>
          <Text fontSize="xs" color="black" textAlign="center" visibility="visible" whiteSpace="pre-wrap">
            {t('registeringDescription')}
          </Text>
        </VStack>
      </Skeleton>
    )
  }

  return (
    <VStack width="19rem" height="28.75rem" spacing={0} borderRadius="0.75rem" boxShadow="md" justifyContent="space-between">
      <VStack
        w="100%"
        h="24.1875rem"
        position="relative"
        spacing={4}
        p={4}
        borderRadius="0.75rem 0.75rem 0 0"
        alignItems="space-between"
        bg={'linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%)'}
      >
        <VStack spacing={0} alignItems="flex-start" w="100%">
          <Text fontSize="lg" fontWeight={500}>
            {uniqueAssetId}
          </Text>
          <Text color="gray.500" fontSize="sm">
            {t('unregistered')}
          </Text>
        </VStack>

        <Box width="100%" minHeight="9rem" borderRadius="xl" overflow="hidden" boxShadow="sm" bg="white">
          <Image opacity={0.5} src="/images/smart_device_black_white.webp" alt="Smart Device" transform="scale(1.2)" userSelect="none" />
        </Box>
        <VStack h="100%" justifyContent="space-between">
          <Text textAlign="center" fontSize="md" fontWeight="500">
            {t('registerNotYet')}
          </Text>
          <HStack spacing={1} alignItems="flex-start">
            <Box pt="0.5rem" h="1.5rem">
              <IoMdInformationCircleOutline size="1.25rem" color="#718096" />
            </Box>
            <Text fontSize="xs" fontWeight="400" color="gray.500">
              {t('registerNow')}
            </Text>
          </HStack>
        </VStack>
      </VStack>
      <VStack h="4.5625rem" spacing={0} alignItems="flex-start" w="100%">
        {isAvaxRefundRequired && (
          <>
            <Divider />
            <Button
              w="100%"
              h="100%"
              variant="ghost"
              leftIcon={<HiOutlineReceiptRefund />}
              colorScheme="yellow"
              size="sm"
              justifyContent={isAvaxRefundWaiting ? 'center' : 'space-between'}
              onClick={onAvaxRefund}
              borderRadius="0"
              isLoading={isAvaxRefundWaiting}
            >
              {t('refund')}
              <BiChevronRight style={{ marginLeft: 'auto' }} />
            </Button>
          </>
        )}

        <Divider />
        <Button
          w="100%"
          h="100%"
          variant="ghost"
          leftIcon={<IoDuplicateOutline />}
          colorScheme="gray"
          size="sm"
          justifyContent="space-between"
          onClick={onRegister}
          borderRadius="0 0 0.75rem 0.75rem"
        >
          {t('register')}
          <BiChevronRight style={{ marginLeft: 'auto' }} />
        </Button>
      </VStack>
    </VStack>
  )
}

export default RegisterAssetCard
