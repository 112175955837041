import { MockSmartDevice, SmartDeviceStates, SmartDeviceType } from '../declerations/smart-device.types'

export const mockSmartDevices: MockSmartDevice[] = [
  {
    uniqueAssetId: 'NL1CSU0P00G007328',
    name: 'Anadolu',
    state: SmartDeviceStates.IDLE,
    type: SmartDeviceType.ANADOLU,
    engineOn: false,
    batteryPercentage: 80,
  },
  {
    uniqueAssetId: 'NL1CSU0B00G000383',
    name: 'Anadolu',
    state: SmartDeviceStates.PARKED,
    type: SmartDeviceType.GEMLIK,
    engineOn: false,
    batteryPercentage: 80,
  },
  {
    uniqueAssetId: 'NL1CSU0P00G007327',
    name: 'Gemlik',
    state: SmartDeviceStates.PARKED_AND_CHARGING,
    type: SmartDeviceType.KAPADOKYA,
    engineOn: false,
    batteryPercentage: 20,
  },
  {
    uniqueAssetId: 'NL1CSU0P20G001871',
    name: 'Gemlik',
    state: SmartDeviceStates.PARKED_AND_CHARGE_COMPLETE,
    type: SmartDeviceType.KULA,
    engineOn: false,
    batteryPercentage: 100,
  },
  {
    uniqueAssetId: 'NL1CSU0P80G007593',
    name: 'Gemlik',
    state: SmartDeviceStates.DRIVING,
    type: SmartDeviceType.OLTU,
    engineOn: true,
    batteryPercentage: 20,
  },
  {
    uniqueAssetId: 'NL1CSU0P90G007330',
    name: 'Kapadokya',
    state: SmartDeviceStates.PARKED_AND_CHARGE_COMPLETE,
    type: SmartDeviceType.PAMUKKALE,
    engineOn: false,
    batteryPercentage: 100,
  },
  {
    uniqueAssetId: 'NL1CSU0P90G001821',
    name: 'Kapadokya',
    state: SmartDeviceStates.PARKED,
    type: SmartDeviceType.ANADOLU,
    engineOn: false,
    batteryPercentage: 100,
  },
  {
    uniqueAssetId: 'NL1CSU0P70G001672',
    name: 'Kapadokya',
    state: SmartDeviceStates.PARKED_AND_CHARGING,
    type: SmartDeviceType.GEMLIK,
    engineOn: false,
    batteryPercentage: 50,
  },
  {
    uniqueAssetId: 'NL1CSU0P00G007068',
    name: 'Kapadokya',
    state: SmartDeviceStates.PARKED_AND_CHARGE_COMPLETE,
    type: SmartDeviceType.KAPADOKYA,
    engineOn: false,
    batteryPercentage: 100,
  },
  {
    uniqueAssetId: 'NL1CSU0P50G002867',
    name: 'Kula',
    state: SmartDeviceStates.DRIVING,
    type: SmartDeviceType.KULA,
    engineOn: true,
    batteryPercentage: 30,
  },
  {
    uniqueAssetId: 'NL1CSU0P80G007738',
    name: 'Kula',
    state: SmartDeviceStates.DRIVING,
    type: SmartDeviceType.OLTU,
    engineOn: true,
    batteryPercentage: 30,
  },
  {
    uniqueAssetId: 'NL1CSU0PX0G002606',
    name: 'Kula',
    state: SmartDeviceStates.DRIVING,
    type: SmartDeviceType.PAMUKKALE,
    engineOn: true,
    batteryPercentage: 30,
  },
  {
    uniqueAssetId: 'NL1CSU0PX0G007028',
    name: 'Kula',
    state: SmartDeviceStates.DRIVING,
    type: SmartDeviceType.ANADOLU,
    engineOn: true,
    batteryPercentage: 30,
  },
  {
    uniqueAssetId: 'NL1CSU0P30G001104',
    name: 'Kula',
    state: SmartDeviceStates.DRIVING,
    type: SmartDeviceType.GEMLIK,
    engineOn: true,
    batteryPercentage: 30,
  },
  {
    uniqueAssetId: 'NL1CSU0P90G007117',
    name: 'Oltu',
    state: SmartDeviceStates.DRIVING,
    type: SmartDeviceType.KAPADOKYA,
    engineOn: true,
    batteryPercentage: 20,
  },
  {
    uniqueAssetId: 'NL1CSU0P30G007100',
    name: 'Oltu',
    state: SmartDeviceStates.DRIVING,
    type: SmartDeviceType.KULA,
    engineOn: true,
    batteryPercentage: 80,
  },
  {
    uniqueAssetId: 'NL1CSU0P20G003295',
    name: 'Oltu',
    state: SmartDeviceStates.PARKED_AND_CHARGE_COMPLETE,
    type: SmartDeviceType.OLTU,
    engineOn: false,
    batteryPercentage: 100,
  },
]

export const unknownSmartDevice: MockSmartDevice = {
  uniqueAssetId: `NL1CSU0P${Math.floor(Math.random() * 1000000)}`,
  name: 'Unknown',
  state: SmartDeviceStates.MAINTENANCE_MODE,
  type: SmartDeviceType.PAMUKKALE,
  engineOn: Math.random() >= 0.5,
  batteryPercentage: Math.floor(Math.random() * 100),
}
