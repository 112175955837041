import React, { FC, useMemo } from 'react'
import { Box, keyframes, usePrefersReducedMotion } from '@chakra-ui/react'
import { BsLightningChargeFill } from 'react-icons/bs'

const ChargeIndicator: FC<{ percentage: number }> = ({ percentage }) => {
  const computedPercentage = useMemo(() => {
    if (percentage > 100) {
      return 100
    }
    if (percentage < 7.5) {
      return 7.5
    }
    return percentage
  }, [percentage])

  const fillAnimation = keyframes`
    from { width: 7.5%; }
    to { width: ${computedPercentage}% }
  `
  const prefersReducedMotion = usePrefersReducedMotion()

  const animation = prefersReducedMotion ? undefined : `${fillAnimation} 0.75s ease-out forwards`

  return (
    <Box width="100%" height="1.5rem" bg="gray.300" p="0.5" borderRadius="full">
      <Box
        width={`${computedPercentage}%`}
        height="100%"
        borderRadius="full"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        pr="0.25rem"
        bg={percentage > 20 ? 'green.500' : 'red.500'}
        animation={animation}
      >
        <BsLightningChargeFill size="0.75rem" color="white" />
      </Box>
    </Box>
  )
}

export default ChargeIndicator
