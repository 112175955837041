import {
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { isWalletAddressValid } from '../../utils/wallet-address.util'
import React, { FC, useEffect, useMemo, useState } from 'react'
import FractionalizedHolderAddModalProps from './fractionalized-holder-add-modal.component.types'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ApiService } from '../../utils/api-service.util'
import { TfiReload } from 'react-icons/tfi'
import { useDebounce } from 'use-debounce'
import AvaxDisplayer from '../avax-displayer/avax-displayer.component'
import { GiCarKey } from 'react-icons/gi'
import ServerEventsTypes, { AddingFractionalizedAssetsCompletedEventData, AddingFractionalizedAssetsFailedEventData } from '../../declerations/server-events.types'
import { eventsManager } from '../../managers/events.manager'
import serverExceptionToast from '../../utils/server-exception-toast.util'
import ServerException from '../../declerations/server-exception.types'
import { AxiosError, AxiosResponse } from 'axios'
import { DryRunAddFractionalizedAssetsCommandResponse } from '@vennyx-org/togg-smart-device-tokenization-api-client'
import getContractExceptionTranslation from '../../utils/contract-exception-translation.util'
import BigNumber from 'bignumber.js'
import { useBalance } from '../../contexes/balance.context'

const FractionalizedHolderAddModal: FC<FractionalizedHolderAddModalProps> = ({ isOpen, onClose, uniqueAssetId }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'fractionalizedHolderAddModal' })
  const toast = useToast()
  const { balance } = useBalance()
  const [newHolderWalletAddress, setNewHolderWalletAddress] = useState('')
  const [walletAddress] = useDebounce(newHolderWalletAddress, 700)
  const [failedAddingHolderWalletAddresses, setFailedAddingHolderWalletAddresses] = useState<string[]>([])
  const [holders, setHolders] = useState<{ walletAddress: string; isLoading: boolean }[]>([])

  const fractionalizedAssetHoldersQuery = useQuery({
    queryKey: ['fractionalizedAssetHolders', uniqueAssetId],
    queryFn: () => ApiService.queriesFractionalizedAssetsUniqueAssetIdGet(uniqueAssetId),
    enabled: !!uniqueAssetId && isOpen,
    staleTime: 1000 * 30,
    refetchOnWindowFocus: false,
  })

  const dryRunAddFractionalizedAssetQuery = useQuery<AxiosResponse<DryRunAddFractionalizedAssetsCommandResponse>, AxiosError<ServerException>>({
    queryKey: ['dryRunAddFractionalizedAsset', uniqueAssetId, walletAddress],
    queryFn: () => ApiService.commandsDryRunAddFractionalizedAssetsPost({ uniqueAssetId: uniqueAssetId, holderAddresses: [walletAddress] }),
    enabled: isWalletAddressValid(walletAddress),
    retry: 1,
    retryDelay: 1000,
    gcTime: 10000,
  })

  const addFractionalizedAssetQuery = useMutation({
    mutationKey: ['addFractionalizedAsset', uniqueAssetId, walletAddress],
    mutationFn: () => ApiService.commandsAddFractionalizedAssetsPost({ uniqueAssetId: uniqueAssetId, holderAddresses: [walletAddress] }),
    onSuccess: async ({ data }) => {
      if (data.isSucceeded) {
        setHolders(holders => [...holders, { walletAddress, isLoading: true }])
        setNewHolderWalletAddress('')
      }
    },
    onError: serverExceptionToast,
  })

  const isBalanceEnough = useMemo(() => {
    if (!dryRunAddFractionalizedAssetQuery.data?.data?.totalGasFee) {
      return false
    }

    return new BigNumber(balance).isGreaterThan(new BigNumber(dryRunAddFractionalizedAssetQuery.data.data.totalGasFee))
  }, [balance, dryRunAddFractionalizedAssetQuery.data?.data?.totalGasFee])

  useEffect(() => {
    if (failedAddingHolderWalletAddresses.length > 0) {
      failedAddingHolderWalletAddresses.forEach(walletAddress => {
        toast({
          title: t('errorAddHolderWalletAddress', { walletAddress }),
          status: 'error',
        })
      })
      setFailedAddingHolderWalletAddresses([])
    }
  }, [failedAddingHolderWalletAddresses, t, toast])

  useEffect(() => {
    const handleAddingFractionalizedAssetsCompleted = (data: AddingFractionalizedAssetsCompletedEventData) => {
      console.log('handleAddingFractionalizedAssetsCompleted', data)
      setHolders(holders => {
        return holders.map(holder => {
          const foundWallet = data.toWallets.find(wallet => wallet === holder.walletAddress)
          if (foundWallet) {
            return { walletAddress: holder.walletAddress, isLoading: false }
          }
          return holder
        })
      })
    }
    const handleAddingFractionalizedAssetsFailed = (data: AddingFractionalizedAssetsFailedEventData) => {
      console.log('handleAddingFractionalizedAssetsFailed', data)
      setFailedAddingHolderWalletAddresses(walletAddresses => [...walletAddresses, data.ownerWalletAddress])
      setHolders(holders => holders.filter(holder => !data.toWallets.includes(holder.walletAddress)))
    }

    eventsManager.subscribe(ServerEventsTypes.ADDING_FRACTIONALIZED_ASSETS_COMPLETED, handleAddingFractionalizedAssetsCompleted)
    eventsManager.subscribe(ServerEventsTypes.ADDING_FRACTIONALIZED_ASSETS_FAILED, handleAddingFractionalizedAssetsFailed)

    return () => {
      eventsManager.unsubscribe(ServerEventsTypes.ADDING_FRACTIONALIZED_ASSETS_COMPLETED, handleAddingFractionalizedAssetsCompleted)
      eventsManager.unsubscribe(ServerEventsTypes.ADDING_FRACTIONALIZED_ASSETS_FAILED, handleAddingFractionalizedAssetsFailed)
    }
  }, [])

  useEffect(() => {
    if (fractionalizedAssetHoldersQuery.isSuccess && fractionalizedAssetHoldersQuery.data?.data.holders) {
      const computedHolders = Object.entries(fractionalizedAssetHoldersQuery.data!.data.holders).map(([key, value]) => {
        return { walletAddress: key, isLoading: value }
      })
      setHolders(computedHolders)
    }
  }, [fractionalizedAssetHoldersQuery.isSuccess, fractionalizedAssetHoldersQuery.data])

  const handleAddHolder = async () => {
    try {
      await addFractionalizedAssetQuery.mutateAsync()
    } catch {
      // Ignored
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{uniqueAssetId}</ModalHeader>
        <ModalBody pb={6}>
          <HStack justifyContent="space-between" mb={4} alignItems="center">
            <FormLabel>{t('holders')}</FormLabel>

            <Button
              variant="ghost"
              size="sm"
              leftIcon={<TfiReload />}
              isDisabled={fractionalizedAssetHoldersQuery.isFetching}
              onClick={() => fractionalizedAssetHoldersQuery.refetch()}
            >
              {t('refresh')}
            </Button>
          </HStack>

          <VStack spacing={4} align="stretch">
            {!fractionalizedAssetHoldersQuery.isFetching &&
              holders.map((holder, index) => {
                if (holder.isLoading) {
                  return (
                    <HStack spacing={4} boxShadow="md" p={2} borderRadius="lg" bg="yellow.100" key={index}>
                      <Spinner width="2rem" height="2rem" />
                      <VStack align="stretch" spacing={1}>
                        <Text fontWeight="500">{t('waitingForHolder')}</Text>
                        <Text>{holder.walletAddress}</Text>
                      </VStack>
                    </HStack>
                  )
                }
                return (
                  <HStack key={index} spacing={4} boxShadow="md" p={2} borderRadius="lg">
                    <GiCarKey size="2rem" />
                    <VStack align="stretch" spacing={1}>
                      <Text fontWeight="500">{t('holder', { index: index + 1 })}</Text>
                      <Text>{holder.walletAddress}</Text>
                    </VStack>
                  </HStack>
                )
              })}
            {!fractionalizedAssetHoldersQuery.isFetching && fractionalizedAssetHoldersQuery.isFetched && holders.length === 0 && <Text color="gray.500">{t('noHolders')}</Text>}
            {fractionalizedAssetHoldersQuery.isFetching && (
              <Center>
                <Spinner />
              </Center>
            )}
            {fractionalizedAssetHoldersQuery.isError && (
              <VStack spacing={2}>
                <Text color="red.500" fontSize="sm" textAlign="center">
                  {t('errorFetchingHolders')}
                </Text>
                <Button colorScheme="red" variant="ghost" w="100%" leftIcon={<TfiReload />} onClick={() => fractionalizedAssetHoldersQuery.refetch()}>
                  {t('tryAgain')}
                </Button>
              </VStack>
            )}
            <Divider />
            <FormControl
              id="newHolderWalletAddress"
              isInvalid={!isWalletAddressValid(newHolderWalletAddress) && newHolderWalletAddress.length !== 0}
              isDisabled={fractionalizedAssetHoldersQuery.isLoading || fractionalizedAssetHoldersQuery.isError}
            >
              <FormLabel>{t('addHolder')}</FormLabel>
              <Input placeholder={t('addHolderPlaceholder')} value={newHolderWalletAddress} onChange={e => setNewHolderWalletAddress(e.target.value)} />
              <FormErrorMessage>{t('invalidWalletAddress')}</FormErrorMessage>
            </FormControl>

            {walletAddress && !dryRunAddFractionalizedAssetQuery.isError && dryRunAddFractionalizedAssetQuery.data?.data?.totalGasFee && (
              <VStack spacing={1} align="stretch" borderWidth="1px" borderRadius="lg" p={4}>
                <Text>{t('estimatedGasPrice')}</Text>
                <Text color="gray.500" fontSize="xs">
                  {t('estimatedGasPriceDesc1')}
                </Text>
                <Text color="gray.500" fontSize="xs" mb={2}>
                  {t('estimatedGasPriceDesc2')}
                </Text>
                <AvaxDisplayer value={dryRunAddFractionalizedAssetQuery.data.data.totalGasFee} />
                <Text color="gray.500" fontSize="xs">
                  {t('estimatedGasPriceDesc3')}
                </Text>
              </VStack>
            )}

            {dryRunAddFractionalizedAssetQuery.isLoading && (
              <Center>
                <Spinner mt={2} ml={2} />
              </Center>
            )}

            {!dryRunAddFractionalizedAssetQuery.isLoading && dryRunAddFractionalizedAssetQuery.isError && (
              <>
                <Text color="red.500" fontSize="sm" textAlign="center">
                  {getContractExceptionTranslation(dryRunAddFractionalizedAssetQuery.error.response?.data?.detail, 'fractionalizedHolderAddModal.errorEstimate')}
                </Text>
                <Button
                  colorScheme="red"
                  variant="ghost"
                  w="100%"
                  leftIcon={<TfiReload />}
                  onClick={() => dryRunAddFractionalizedAssetQuery.refetch()}
                  isDisabled={dryRunAddFractionalizedAssetQuery.isFetching}
                >
                  {t('tryAgain')}
                </Button>
              </>
            )}

            <Button onClick={handleAddHolder} isDisabled={!dryRunAddFractionalizedAssetQuery.isSuccess || !isBalanceEnough} isLoading={addFractionalizedAssetQuery.isPending}>
              {dryRunAddFractionalizedAssetQuery.isFetched && dryRunAddFractionalizedAssetQuery.isSuccess && !isBalanceEnough ? t('notEnoughBalance') : t('approve')}
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default FractionalizedHolderAddModal
