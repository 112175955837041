import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { User } from 'oidc-client-ts'
import authConfig from '../configs/auth.config'
import * as Services from '@vennyx-org/togg-smart-device-tokenization-api-client'
import appConfig from '../configs/app.config'

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

const onRequestSucceed = async (response: AxiosResponse): Promise<AxiosResponse> => {
  return response
}

const onRequestFailed = (error: AxiosError<unknown>) => {
  throw error
}
function getUser() {
  const oidcStorage = sessionStorage.getItem(`oidc.user:${authConfig.authority}:${authConfig.client_id}`)
  if (!oidcStorage) {
    return null
  }

  return User.fromStorageString(oidcStorage)
}

const onRequest = async (req: InternalAxiosRequestConfig<unknown>) => {
  const accessToken = getUser()?.access_token
  if (accessToken) {
    req.headers['Authorization'] = `Bearer ${accessToken}`
  }

  return req
}

axios.interceptors.request.use(onRequest)
axios.interceptors.response.use(onRequestSucceed, onRequestFailed)

export const ApiService = Services.ToggSmartDeviceTokenizationServerApiFactory(undefined, appConfig.apiUrl, axios)
