import React, { useMemo } from 'react'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import BigNumber from 'bignumber.js'

interface NumberDisplayerProps {
  value: string | number
}

const AvaxDisplayer: React.FC<NumberDisplayerProps> = ({ value }) => {
  const resultNumber = useMemo(() => {
    let parsedNumber = new BigNumber(value)

    if (parsedNumber.isNaN()) {
      return '-'
    }

    parsedNumber = parsedNumber.dividedBy(new BigNumber(10).pow(18))

    parsedNumber = new BigNumber(parsedNumber.toFixed(5, BigNumber.ROUND_HALF_UP))

    return parsedNumber.toString()
  }, [value])

  return (
    <Flex align="center" flexWrap="nowrap">
      <Box mr={2} w="20px" h="20px">
        <Image src="/images/avalanche-avax-logo.webp" alt="AVAX" width="20px" height="20px" title="AVAX" />
      </Box>
      <Text fontSize="16px" fontWeight="700" color="black">
        {resultNumber}
      </Text>
    </Flex>
  )
}

export default AvaxDisplayer
