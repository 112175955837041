import * as React from 'react'
import { SVGProps } from 'react'

const ToggOnlyLogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={40} fill="none" viewBox="0 0 90 35" {...props}>
    <path fill="#00C1E6" d="M36.66.588 0 18.015l36.681 17.397-17.975-17.407L36.66.588ZM36.66.588l36.682 17.397-36.66 17.427 17.955-17.417L36.66.588Z" />
  </svg>
)

export default ToggOnlyLogoIcon
