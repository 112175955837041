import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { theme, toastOptions } from './configs/chakra.config'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { AuthContext, AuthProvider } from 'react-oidc-context'
import authConfig from './configs/auth.config'
import BalanceContextProvider from './contexes/balance.context'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import HomePage from './routes/home/home.page'
import LoginPage from './routes/login/login.page'

import './assets/fonts/fedra-sans/stylesheet.scss'
import './styles/main.scss'
import './i18n'
import EventsContextProvider from './contexes/events.context'

const authenticatedRoutes = createBrowserRouter([
  {
    path: '*',
    element: <HomePage />,
  },
])

const unauthenticatedRoutes = createBrowserRouter([
  {
    path: '*',
    element: <LoginPage />,
  },
])

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <AuthProvider {...authConfig}>
    <AuthContext.Consumer>
      {auth => (
        <QueryClientProvider client={queryClient}>
          <EventsContextProvider>
            <BalanceContextProvider>
              <ChakraProvider theme={theme} toastOptions={toastOptions}>
                {!auth?.isLoading && <RouterProvider router={auth?.isAuthenticated ? authenticatedRoutes : unauthenticatedRoutes} />}
              </ChakraProvider>
            </BalanceContextProvider>
          </EventsContextProvider>
        </QueryClientProvider>
      )}
    </AuthContext.Consumer>
  </AuthProvider>
)

reportWebVitals()
