import ServerException from '../declerations/server-exception.types'
import { AxiosError } from 'axios'
import { createStandaloneToast } from '@chakra-ui/react'
import { theme, toastOptions } from '../configs/chakra.config'
import i18n from '../i18n'

const { toast } = createStandaloneToast({ theme, defaultOptions: toastOptions.defaultOptions })

const serverExceptionToast = (error: AxiosError<ServerException>) => {
  if (error.response?.data.type) {
    toast({
      title: i18n?.t('systemWideErrors.' + error.response.data.type),
      status: 'error',
    })
  } else {
    toast({
      title: i18n?.t('systemWideErrors.UnexpectedException'),
      status: 'error',
    })
  }
}

export default serverExceptionToast
