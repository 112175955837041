enum ServerEventsTypes {
  ASSET_REGISTER_PROCESSING = 'asset-register-processing',
  ASSET_REGISTERED = 'asset-registered',
  ASSET_REGISTRATION_FAILED = 'asset-registration-failed',
  AVAX_REFUND_REQUIRED = 'avax-refund-required',
  ADDING_FRACTIONALIZED_ASSETS_COMPLETED = 'adding-fractionalized-assets-completed',
  ADDING_FRACTIONALIZED_ASSETS_FAILED = 'adding-fractionalized-assets-failed',
  UNKNOWN = '',
}

export interface AssetRegisterProcessingEventData {
  isProcessing: boolean
  isAvaxRefundRequired: boolean
  uniqueAssetId: string
  userAccountId: string
}

export interface AssetRegisteredEventData {
  isProcessing: boolean
  isAvaxRefundRequired: boolean
  uniqueAssetId: string
  userAccountId: string
  masterAssetKeyTokenId: string
}

export interface AssetRegistrationFailedEventData {
  isProcessing: boolean
  isAvaxRefundRequired: boolean
  uniqueAssetId: string
  userAccountId: string
}

export interface AvaxRefundRequiredEventData {
  isProcessing: boolean
  isAvaxRefundRequired: boolean
  uniqueAssetId: string
  userAccountId: string
}

export interface AddingFractionalizedAssetsCompletedEventData {
  isProcessing: boolean
  uniqueAssetId: string
  userAccountId: string
  ownerWalletAddress: string
  toWallets: string[]
}

export interface AddingFractionalizedAssetsFailedEventData {
  isProcessing: boolean
  uniqueAssetId: string
  userAccountId: string
  ownerWalletAddress: string
  toWallets: string[]
}

export default ServerEventsTypes
