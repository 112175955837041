import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import SmartDeviceInfo from '../smart-device-info/smart-device-info.component'
import React, { FC } from 'react'
import SmartDeviceInfoModalProps from './smart-device-info-modal.component.types'

const SmartDeviceInfoModal: FC<SmartDeviceInfoModalProps> = ({ isOpen, onClose, smartDevice }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{smartDevice?.uniqueAssetId}</ModalHeader>
        <ModalBody pb={6}>{smartDevice && <SmartDeviceInfo smartDevice={smartDevice} />}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SmartDeviceInfoModal
