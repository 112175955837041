import i18n from '../i18n'

const getContractExceptionTranslation = (message?: string, fallback?: string) => {
  const translationKeyPrefix = 'smartContractErrors.'
  const splitMessage = message?.split('execution reverted: ')
  const translationKey = splitMessage?.[1] || splitMessage?.[0]
  const fullTranslationKey = translationKeyPrefix + translationKey

  const translation = i18n?.t(fullTranslationKey)

  if (translation === fullTranslationKey) {
    return i18n?.t(`${fallback}`)
  }

  return translation
}

export default getContractExceptionTranslation
