console.log(`Project running in ${process.env.NODE_ENV} mode`)

if (!process.env.REACT_APP_API_URL) {
  console.error('REACT_APP_API_URL is not set')
}

if (!process.env.REACT_APP_API_EVENTS_URL) {
  console.error('REACT_APP_API_EVENTS_URL is not set')
}

const appConfig = {
  env: process.env.NODE_ENV,
  apiUrl: process.env.REACT_APP_API_URL ?? '',
  eventsApiUrl: process.env.REACT_APP_API_EVENTS_URL ?? '',
  appVersion: '1.0.2',
}

export default appConfig
