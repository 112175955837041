import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import { BalanceContextProps } from './balance.context.types'
import { useAuth } from 'react-oidc-context'
import { ApiService } from '../utils/api-service.util'
import { useQuery } from '@tanstack/react-query'

export const BalanceContext = createContext<Partial<BalanceContextProps>>({})
export const useBalance = () => useContext(BalanceContext) as BalanceContextProps
const BalanceContextProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuth()
  const myBalanceQuery = useQuery({
    queryKey: ['myBalance'],
    queryFn: ApiService.queriesMyBalanceGet,
    enabled: !!auth.user,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: 'always',
    refetchIntervalInBackground: true,
    refetchInterval: 30000,
    retryOnMount: true,
    retry: 1,
    retryDelay: 2000,
    staleTime: 1000 * 30,
  })

  const retry = useMemo(
    () => async () => {
      await myBalanceQuery.refetch()
    },
    [myBalanceQuery]
  )

  const value: BalanceContextProps = useMemo(
    () => ({
      isLoading: myBalanceQuery.isFetching,
      isPending: myBalanceQuery.isPending,
      balance: myBalanceQuery.data?.data.balance ?? '',
      retry,
    }),
    [myBalanceQuery.isFetching, myBalanceQuery.isPending, myBalanceQuery.data?.data.balance, retry]
  )

  return <BalanceContext.Provider value={value}>{children}</BalanceContext.Provider>
}

export default BalanceContextProvider
