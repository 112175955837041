import React, { useCallback } from 'react'
import { Avatar, HStack, IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text, useColorModeValue, useMediaQuery } from '@chakra-ui/react'
import { useAuth } from 'react-oidc-context'
import { useTranslation } from 'react-i18next'
import AvaxDisplayer from '../avax-displayer/avax-displayer.component'
import { useBalance } from '../../contexes/balance.context'
import { TfiReload } from 'react-icons/tfi'
import ToggLogoIcon from '../icons/togg-logo.icon'
import ToggOnlyLogoIcon from '../icons/togg-only-logo.icon'
import appConfig from '../../configs/app.config'
import { LuLogOut } from 'react-icons/lu'

const Navbar = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'navbar' })
  const auth = useAuth()
  const { isLoading, isPending, balance, retry } = useBalance()
  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const handleLogout = useCallback(async () => {
    await auth.removeUser()
    await auth.signoutRedirect()
  }, [auth])

  return (
    <HStack h={16} alignItems="center" justifyContent="space-between" bg={useColorModeValue('gray.100', 'gray.900')} paddingX={{ base: 2, md: 4 }}>
      <HStack spacing={{ base: 0, md: 4 }} alignItems="center">
        {isMobile ? <ToggOnlyLogoIcon width="3rem" /> : <ToggLogoIcon height="2rem" />}
      </HStack>
      {auth.isAuthenticated && auth.user && (
        <HStack>
          <HStack border="2px solid #CBD5E0" borderRadius="lg" px={{ base: 2, md: 4 }} py={2} opacity={isLoading ? 0.5 : 1}>
            {!isPending && <AvaxDisplayer value={balance} />}
            <IconButton
              aria-label="reload-balance"
              variant="unstyled"
              padding={0}
              minW="1rem"
              height="1.5rem"
              isLoading={isPending}
              icon={<TfiReload />}
              onClick={retry}
              isDisabled={isLoading}
            />
          </HStack>

          <Menu placement="bottom-end">
            <MenuButton as={IconButton} rounded="full" variant="link" cursor="pointer" minW={0}>
              <Avatar
                size="md"
                src={`https://ui-avatars.com/api/?background=CBD5E0&name=${encodeURIComponent(auth.user!.profile.name!)}`}
                width={{ base: '2rem', md: '3rem' }}
                height={{ base: '2rem', md: '3rem' }}
              />
            </MenuButton>
            <MenuList>
              <Text fontSize="sm" fontWeight="500" px={3}>
                {auth.user!.profile.name}
              </Text>
              <MenuDivider />
              <MenuItem onClick={handleLogout}>
                <LuLogOut />
                <Text fontSize="sm" ml={1} fontWeight="600">
                  {t('logout')}
                </Text>
              </MenuItem>
              <MenuDivider />
              <Text fontSize="xs" textAlign="center">
                {t('version')} {appConfig.appVersion}
              </Text>
            </MenuList>
          </Menu>
        </HStack>
      )}
    </HStack>
  )
}

export default Navbar
