import React, { FC, useState } from 'react'
import { Box, Button, Divider, Flex, HStack, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Text, useToast, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import ChargeIndicator from '../charge-indicator/charge-indicator.component'
import { TbLock, TbLockOpen } from 'react-icons/tb'
import { GrUserAdmin } from 'react-icons/gr'
import { LuPower, LuPowerOff } from 'react-icons/lu'
import { PiDotsThreeVertical } from 'react-icons/pi'
import ControlCardProps from './control-card.component.types'
import { MdOutlineInfo } from 'react-icons/md'
import { useQuery } from '@tanstack/react-query'
import { ApiService } from '../../utils/api-service.util'
import { GiCarKey } from 'react-icons/gi'
import { BiChevronRight } from 'react-icons/bi'

const ControlCard: FC<ControlCardProps> = ({ uniqueAssetId, state, engineOn, imageUrl, ownerWalletAddress, batteryPercentage, onHoldersClick, onInfoClick, isOwner }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'controlCard' })
  const toast = useToast()
  const [engineStarted, setEngineStarted] = useState(engineOn)
  const [loadingEngineStarted, setLoadingEngineStarted] = useState(false)
  const [lockIsLoading, setLockIsLoading] = useState(false)
  const [unlockIsLoading, setUnlockIsLoading] = useState(false)

  const canIUseAssetUniqueAssetQuery = useQuery({
    queryKey: ['canIUseAssetUniqueAsset', uniqueAssetId],
    queryFn: () => ApiService.queriesCanIUseAssetUniqueAssetIdGet(uniqueAssetId),
    enabled: false,
    retry: 1,
    retryDelay: 1000,
    refetchOnWindowFocus: false,
  })

  const handleEngineStarted = async () => {
    setLoadingEngineStarted(true)
    const { data } = await canIUseAssetUniqueAssetQuery.refetch()
    if (data?.data?.canUse) {
      setEngineStarted(!engineStarted)
    } else {
      toast({
        title: t('youCantUseThisAsset'),
        status: 'error',
      })
    }

    setLoadingEngineStarted(false)
  }

  const handleLockClick = async () => {
    setLockIsLoading(true)
    const { data } = await canIUseAssetUniqueAssetQuery.refetch()

    if (!data?.data?.canUse) {
      toast({
        title: t('youCantUseThisAsset'),
        status: 'error',
      })
    }

    setLockIsLoading(false)
  }

  const handleUnlockClick = async () => {
    setUnlockIsLoading(true)
    const { data } = await canIUseAssetUniqueAssetQuery.refetch()

    if (!data?.data?.canUse) {
      toast({
        title: t('youCantUseThisAsset'),
        status: 'error',
      })
    }

    setUnlockIsLoading(false)
  }

  return (
    <VStack width="19rem" height="28.75rem" spacing={0} borderRadius="0.75rem" boxShadow="md">
      <VStack w="100%" position="relative" spacing={4} p={4} borderRadius="0.75rem 0.75rem 0 0" alignItems="flex-start" bg={'linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%)'}>
        <HStack w="100%" spacing={0} justifyContent="space-between" alignItems="flex-start">
          <VStack spacing={0} alignItems="flex-start" w="100%">
            <Text fontSize="lg" fontWeight={500}>
              {uniqueAssetId}
            </Text>
            <Text color="gray.500" fontSize="sm">
              {t(state)}
            </Text>
          </VStack>
          <Box mr={-2}>
            <Menu>
              <MenuButton>
                <PiDotsThreeVertical size="1.5rem" width="0.5rem" />
              </MenuButton>
              <MenuList>
                {isOwner && (
                  <MenuItem onClick={onHoldersClick} icon={<GrUserAdmin size="1rem" />}>
                    {t('users')}
                  </MenuItem>
                )}
                <MenuItem onClick={onInfoClick} icon={<MdOutlineInfo size="1rem" />}>
                  {t('info')}
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </HStack>

        <Box width="100%" height="9rem" borderRadius="xl" overflow="hidden" boxShadow="sm" bg="white">
          <Image src={imageUrl} alt="Smart Device" transform="scale(1.2)" userSelect="none" />
        </Box>
        <ChargeIndicator percentage={batteryPercentage} />
        <Flex justifyContent="space-between" alignItems="center" w="80%" m="0 auto">
          <VStack spacing="0">
            <IconButton
              width="3rem"
              height="3rem"
              aria-label="lock"
              icon={<TbLock />}
              borderRadius="full"
              border="3px solid"
              borderColor="gray.200"
              onClick={handleLockClick}
              isLoading={lockIsLoading}
            />
            <Text fontSize="xs" color="gray.500" textAlign="center" userSelect="none">
              {t('lock')}
            </Text>
          </VStack>
          <VStack spacing="0">
            <IconButton
              width="4.5rem"
              height="4.5rem"
              aria-label="start engine"
              icon={engineStarted ? <LuPowerOff size="2rem" color="white" /> : <LuPower size="2rem" color="white" />}
              borderRadius="full"
              colorScheme="red"
              border="3px solid"
              borderColor="gray.200"
              isLoading={loadingEngineStarted}
              onClick={handleEngineStarted}
            />
            <Text fontSize="xs" color="gray.500" textAlign="center" userSelect="none">
              {engineStarted ? t('stopEngine') : t('startEngine')}
            </Text>
          </VStack>
          <VStack spacing="0">
            <IconButton
              width="3rem"
              height="3rem"
              aria-label="unlock"
              icon={<TbLockOpen />}
              borderRadius="full"
              border="3px solid"
              borderColor="gray.200"
              onClick={handleUnlockClick}
              isLoading={unlockIsLoading}
            />
            <Text fontSize="xs" color="gray.500" textAlign="center" userSelect="none">
              {t('unlock')}
            </Text>
          </VStack>
        </Flex>
      </VStack>
      <VStack spacing={0} w="100%" alignItems="flex-start" borderRadius="0 0 0.75rem 0.75rem">
        {isOwner ? (
          <>
            <Divider />
            <Button
              w="100%"
              h="2.5625rem"
              variant="ghost"
              leftIcon={<GrUserAdmin />}
              colorScheme="gray"
              size="sm"
              justifyContent="space-between"
              onClick={onHoldersClick}
              borderRadius="0"
            >
              {t('users')}
              <BiChevronRight style={{ marginLeft: 'auto' }} />
            </Button>
            <HStack height="2rem" alignItems="center" justifyContent="center" w="100%" spacing={2} bg="#00C1E6" borderRadius="0 0 0.75rem 0.75rem">
              <GrUserAdmin size="1.25rem" color="white" />
              <Text color="white" fontSize="sm" fontWeight={500} textAlign="center">
                {t('youAreKeyOwner')}
              </Text>
            </HStack>
          </>
        ) : (
          <>
            <Divider />
            <Flex height="2.5625rem" alignItems="center" justifyContent="flex-start" w="100%" px="0.75rem">
              <Text fontSize="sm" color="gray.800" fontWeight="600" textAlign="center">
                {t('owner')} {ownerWalletAddress.slice(0, 14)}...{ownerWalletAddress.slice(-14)}
              </Text>
            </Flex>
            <HStack height="2rem" alignItems="center" justifyContent="center" w="100%" spacing={2} bg="#DF392E" borderRadius="0 0 0.75rem 0.75rem">
              <GiCarKey size="1.5rem" color="white" />
              <Text color="white" fontSize="sm" fontWeight={500} textAlign="center">
                {t('youAreKeyHolder')}
              </Text>
            </HStack>
          </>
        )}
      </VStack>
    </VStack>
  )
}

export default ControlCard
